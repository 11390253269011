import { useState } from "react";

import VuiButton from "components/VuiButton";
import { Modal } from "antd";
import { CardMedia } from "@mui/material";

function Affiliate({ color, ...rest }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <VuiButton
        component="a"
        target="_blank"
        rel="noreferrer"
        variant="gradient"
        color={color}
        fullWidth
        onClick={handleClick}
      >
        Become an Affiliate
      </VuiButton>
      <Modal
        className="capitalize"
        width={1100}
        title="Become Affiliate"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CardMedia
          component="iframe"
          image="https://www.youtube.com/embed/EiATDmdQkCM?si=3yNaNtblGYCe2oB1"
          sx={{ width: 1024, height: 640 }}
          controls
        />
      </Modal>
    </>
  );
}

export default Affiliate;
