import { useState } from 'react'
import { Card, Stack } from "@mui/material";
import { Form, Divider, Input, Button, Col, Row } from "antd";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { useDispatch, useSelector } from 'react-redux';
import { changeAccountUsername } from 'store/userSlice';
import { userSuccessfullyValidated } from 'store/userSlice';
import { changeAccountPassword } from 'store/userSlice';
import { changeAccountReferralLink } from 'store/userSlice';

const EditInformations = ({message}) => {
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const dispatch = useDispatch()
  const [usernameChangeForm] = Form.useForm();
  const [passwordChangeForm] = Form.useForm();
  const [referralLinkChangeForm] = Form.useForm();
  const user = useSelector((state) => state.user.user);
  //input styles
  const inputStyle = {
    background: "none",
    backgroundColor: "none",
    border: "0.5px solid rgba(226, 232, 240, 0.4)",
    borderRadius: "0.9375rem",
  };
  //button styles
  const buttonStyle = {
    width: "100%",
    padding: "0",
  };
  //submit the new username
  const usernameChangeSubmit = async (data) => {
    //Loading button animation
    setLoading1(true)
    try {
      let res = await dispatch(changeAccountUsername(data))
      if(res.payload.statusCode === 200){
        //Feedback the messsage
        message.success("Successfully changed username")
        //update the new user into the userStore
        dispatch(userSuccessfullyValidated(res.payload.data))
        //reset the fields in the form
        usernameChangeForm.resetFields()
      }else{
        message.error(res.payload.message)
      }
    } catch ( err ) {
      message.error("Something went wrong, please try again later")
    } 
    setLoading1(false)
  };
  //submit the new password
  const passwordChangeSubmit = async (data) => {
    //Loading button animation
    setLoading2(true)
    try {
      let res = await dispatch(changeAccountPassword(data))
      if(res.payload.statusCode === 200){
        //Feedback the messsage
        message.success("Successfully changed password")
        //reset the fields in the form
        passwordChangeForm.resetFields()
      }else{
        message.error(res.payload.message)
      }
    } catch ( err ) {
      message.error("Something went wrong, please try again later")
    } 
    setLoading2(false)
  };
  //submit the new referral link
  const referralLinkChangeSubmit = async (data) => {
    //Loading button animation
    setLoading3(true)
    try {
      let res = await dispatch(changeAccountReferralLink(data))
      if(res.payload.statusCode === 200){
        //Feedback the messsage
        message.success("Successfully changed referral link")
        //update the new user into the userStore
        dispatch(userSuccessfullyValidated(res.payload.data))
      }else{
        message.error(res.payload.message)
      }
    } catch ( err ) {
      message.error("Something went wrong, please try again later")
    } 
    setLoading3(false)
  };

  return (
    <Card style={{ height: "100%" }}>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="6px">
          Edit your information
        </VuiTypography>
        <VuiTypography
          variant="button"
          color="text"
          fontWeight="regular"
          mb="30px"
        >
          This is the place where you can change your profile information
        </VuiTypography>
        <Stack>
          <Form
            form={usernameChangeForm}
            layout="vertical"
            component="form"
            role="form"
            onFinish={usernameChangeSubmit}
            initialValues={{
              username: user.name,
            }}
          >
            <Form.Item label="User name" required className="m-0" >
              <Row justify="space-between">
                <Col md={14} xs={10} sm={14} xxl={18}>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: "New username is required" }
                    ]}
                  >
                    <Input
                      type="username"
                      placeholder="Your username..."
                      fontWeight="500"
                      style={inputStyle}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} xs={12} sm={8} xxl={4}>
                  <Form.Item>
                    <Button htmlType="submit" loading={loading1} style={buttonStyle} type="primary">
                      Update Profile
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>

          <Divider />

          <Form form={passwordChangeForm} layout="vertical" component="form" role="form" onFinish={passwordChangeSubmit}>
            <Form.Item label="Current password" required className="m-0">
              <Row justify="space-between">
                <Col md={14} xs={10} sm={14} xxl={18}>
                  <Form.Item
                    name="currentPassword"
                    rules={[
                      {
                        required: true,
                        message: "Current password is required",
                      },
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Your current password..."
                      fontWeight="500"
                      style={inputStyle}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} xs={12} sm={8} xxl={4}>
                  <Form.Item>
                    <Button htmlType="submit" loading={loading2} style={buttonStyle} type="primary">
                      Change Password
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="New password" required className="m-0">
              <Row justify="space-between">
                <Col md={14} xs={10} sm={14} xxl={18}>
                  <Form.Item name="newPassword" 
                    rules={[
                      {
                        required: true,
                        message: "New password is required",
                      },
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Your new password..."
                      fontWeight="500"
                      style={inputStyle}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="Confirm new password" required>
              <Row justify="space-between">
                <Col md={14} xs={10} sm={14} xxl={18}>
                  <Form.Item name="newConfirmPassword" 
                    rules={[
                      {
                        required: true,
                        message: "Confirm password is required",
                      },({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The passwords do not match'));
                        },
                      })
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Confirm your new password..."
                      fontWeight="500"
                      style={inputStyle}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>

          <Divider />

          <Form
            form={referralLinkChangeForm}
            layout="vertical"
            component="form"
            role="form"
            onFinish={referralLinkChangeSubmit}
            initialValues={{
              referralLink: user.referral_link,
            }}
          >
            <Form.Item label={
              <>
                <span className="mr-4">Referral Link</span>
                <a href="https://makergpt.trackdesk.com/" target="_blank" rel="nofollow noopener">
                  <Button type="primary">
                    Get Referral Link
                  </Button>
                </a>
              </>
              } className="m-0" >
              <Row justify="space-between">
                <Col md={14} xs={10} sm={14} xxl={18}>
                  <Form.Item
                    name="referralLink"
                  >
                    <Input
                      type="referralLink"
                      placeholder="Your Referral Link..."
                      fontWeight="500"
                      style={inputStyle}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} xs={12} sm={8} xxl={4}>
                  <Form.Item>
                    <Button htmlType="submit" loading={loading3} style={buttonStyle} type="primary">
                      Update Referral Link
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>

        </Stack>
      </VuiBox>
    </Card>
  );
};

export default EditInformations;
